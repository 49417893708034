import React from 'react';
import {
  Modal,
  StyleSheet
} from 'react-native';

import { BasicModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../common/components/basicmodal';
import { commonModalStyle } from '../../../common/components/basicmodal';
import { BOHTable, BOHTBody, BOHTD, BOHTH2, BOHTHead, BOHTR } from '../../../common/components/bohtable';

const MessageHistoryModal = ({ isModalVisible, history, closeModal }) => {
  const InitialWidths = [150, 150, 200];

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={isModalVisible}
    >
      <BasicModalContainer>
        <ModalHeader label={'History'} closeModal={closeModal} />
        <ModalBody>
          <BOHTable>
            <BOHTHead>
              <BOHTR>
                <BOHTH2 width={InitialWidths[0]}>{'Order Number'}</BOHTH2>
                <BOHTH2 width={InitialWidths[1]}>{'Phone Number'}</BOHTH2>
                <BOHTH2 width={InitialWidths[2]}>{'Time'}</BOHTH2>
              </BOHTR>
            </BOHTHead>
            <BOHTBody>
            {history.map((message, index) => (
              <BOHTR key={index}>
                <BOHTD width={InitialWidths[0]}>{message.order_number}</BOHTD>
                <BOHTD width={InitialWidths[1]}>{message.phone_number}</BOHTD>
                <BOHTD width={InitialWidths[2]}>{message.timestamp}</BOHTD>
              </BOHTR>
            ))}
            </BOHTBody>
          </BOHTable>
          {/* {history.map((message, index) => (
            <View key={index} style={addstyles.messageItem}>
              <Text>{message.order_number}</Text>
              <Text>{message.phone_number}</Text>
              <Text style={addstyles.timestamp}>{message.timestamp}</Text>
            </View>
          ))} */}
        </ModalBody>
      </BasicModalContainer>
    </Modal>
  );
};

const styles = commonModalStyle;

const addstyles = StyleSheet.create({
  messageItem: {
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  timestamp: {
    fontSize: 12,
    color: 'gray',
    marginTop: 5,
  },
});


export default MessageHistoryModal;
